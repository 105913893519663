/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import Layout from '../../components/layout';
import Navbar from '../../components/navbar/navbar';
import MobileNavbar from '../../components/mobile-navbar/mobile-navbar';

import { LayoutUI, Header, Stack, TextStyle } from '../../ui-kit';
import * as styles from './Terms.module.scss';

const Terms = () => (
  <Layout>
    <Navbar />
    <MobileNavbar />
    <div>
      <LayoutUI narrow>
        <div className={styles.TermsLayout}>
          <TextStyle center>
            <Stack alignment="center" vertical>
              <div className={styles.TermsHeader}>
                <Header>Terms &amp; Conditions</Header>
              </div>
            </Stack>
          </TextStyle>
          <p>
            <strong>TERMS OF USE</strong>
          </p>
          <p>
            <strong>1. INTRODUCTION</strong>
          </p>
          <p>
            Welcome to{' '}
            <a href="http://www.projecthappiness.org">
              www.projecthappiness.org
            </a>{' '}
            (the “Website”). The Website is owned and operated by Project
            Happiness (“PH”, “us” or “we”).
          </p>
          <p>
            PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THE WEBSITE.
            By using the Website, you signify your agreement to these Terms of
            Use. If you do not agree to these Terms of Use, you may not use the
            Website. In addition, when you use any of our current or future
            services, you will also be subject to our guidelines, terms,
            conditions and agreements applicable to those services (such
            guidelines, terms, conditions and agreements, together with these
            Terms of Use, collectively, the “Terms”). If these Terms of Use are
            inconsistent with the guidelines, terms and agreements applicable to
            those services, these Terms of Use will control.
          </p>
          <p>
            <strong>2. PRIVACY AND YOUR ACCOUNT</strong>
            <br /> Please review our Privacy Policy, which is incorporated by
            reference herein and also governs your use of the Website, to
            understand our privacy practices.
            <br /> We may sell products usable or viewable by children, but sell
            them to adults who can purchase with a credit card or other
            permitted payment method. If you are under 18, you may use the
            Website only with the involvement of a parent or guardian. We
            reserve the right to refuse service, terminate accounts, remove or
            edit content, or cancel orders in our sole discretion.
          </p>
          <p>
            <strong>3. CONSIDERATION</strong>
            <br /> You agree that these Terms of Use are supported by reasonable
            and valuable consideration, the receipt and adequacy of which you
            hereby acknowledge, including, without limitation, your access to
            and use of the Website and data, materials and information available
            at or through the Website.
          </p>
          <p>
            <strong>4. PROPRIETARY RIGHTS; RESTRICTIONS ON USE</strong>
            <br /> All content contained on the Website, such as text, graphics,
            logos, icons, images, audio and video clips, digital downloads, data
            compilations, and software (collectively, “Content”), is our
            property or the property of our licensors or licensees, and the
            compilation of the Content on the Website is our exclusive property,
            protected by United States and international copyright laws,
            treaties and conventions. All software used on the Website is our
            property or the property of our software suppliers and protected by
            United States and international copyright laws, treaties and
            conventions.
          </p>
          <p>
            Any trademarks, service marks, graphics, logos, page headers, icons,
            scripts and trade names (each, a “Mark”) contained on the Website
            are proprietary to us or our licensors or licensees. Our Marks may
            not be used (a) in connection with any product or service that is
            not ours, (b) in any manner that is likely to cause confusion among
            users, or (c) to disparage or discredit PH or anyone else. All other
            Marks not owned by PH that appear on the Website are the property of
            their respective owners, who may or may not be affiliated with,
            connected to, or sponsored by us. Without limiting the foregoing,
            all goodwill arising from any use of PH Marks shall insure solely to
            PH.
          </p>
          <p>
            Except as expressly permitted by these Terms, no Content of the
            Website or any other Internet site owned, operated, licensed, or
            controlled by us may be copied, reproduced, republished, downloaded
            (other than page caching), uploaded, posted, transmitted or
            distributed in any way, or sold, resold, or otherwise exploited for
            any commercial purpose. You shall: (a) keep intact all copyright,
            trademark and other proprietary rights notices; (b) not modify any
            of the Content; (c) not use any Content in a manner that suggests an
            association with any of our products, services or brands; and (d)
            not download Content so as to avoid future downloads from the
            Website. Your use of Content on any other website or computer
            environment is strictly prohibited.
          </p>
          <p>
            You further agree not to: resell or make any commercial use of the
            Website or any Content (except as expressly permitted under these
            Terms); collect and use any product listings, descriptions, or
            prices; download or copy account information for the benefit of
            anyone else; or use any form of data mining, robots, or similar data
            gathering and extraction tools. You may not frame, or utilize
            framing techniques to enclose any Content or other proprietary
            information, or use any meta tags or any other “hidden text”
            utilizing any such intellectual property, without our and each
            applicable owner’s express written consent.
          </p>
          <p>
            <strong>5. CURRICULUM MATERIALS</strong>
            <br /> 5.1 Curriculum Materials Overview. PH owns or has the rights
            to, and may make available on the Website, certain educational and
            training materials and curricula (the “Curriculum Materials”), which
            may include some or all of the following materials: (i) Assessment
            Tools; (ii) Circles of Happiness; (iii) Get Happy &amp; Cultivate
            Compassion in 15 Minutes a Day; (iv) Jumpstart to Happiness; (v) The
            H.A.P.P.I.N.E.S.S. Series: Enhancing Social and Emotional Skills for
            Elementary Schools (K-5); (vi) The H.A.P.P.I.N.E.S.S. Series:
            Enhancing Social and Emotional Skills for Elementary Schools (Middle
            School); (vii) Leadership Club Guidebook (High School); and (viii)
            Happiness Habits. Curriculum Materials may include instructional
            videos, online courses, and other materials which are available to
            purchase through the Website (“Paid Curriculum Materials”) and/or
            materials that are generally accessible through the Website at no
            additional charge (“Free Curriculum Materials”). All Curriculum
            Materials are protected by United States copyright, trade dress,
            patent, and trademark laws, international conventions, and all other
            relevant intellectual property and proprietary rights, and
            applicable laws. PH reserves all rights in the Curriculum Materials
            not expressly granted in these Terms.
          </p>
          <p>
            5.2 License to Free Curriculum Materials. Unless otherwise
            indicated, all Free Curriculum Materials are, subject at all times
            to the additional terms and conditions of these Terms, made
            available to you under the Creative Commons
            Attribution-NonCommercial-NoDerivs 3.0 United States License
            (available at{' '}
            <a href="http://creativecommons.org/licenses/by-nc-nd/3.0/us/">
              http://creativecommons.org/licenses/by-nc-nd/3.0/us/
            </a>
            ) (the “Creative Commons License”). By downloading or otherwise
            accessing such Free Curriculum Materials, you agree to comply with
            all the terms of the Creative Commons License, in addition to the
            terms and conditions of this Agreement.
          </p>
          <p>
            5.3 License to Paid Curriculum Materials. When you purchase Paid
            Curriculum Materials through the Website, your purchase may include
            (i) physical copies of books and/or other Paid Curriculum Materials
            that are shipped to the address you specify at the time of purchase
            (“PH Products”), (ii) electronic copies of written instructional
            materials (“Electronic Materials”), and/or (iii) a license for you
            or students authorized by you (“Authorized Users”) to access and
            view interactive online content, online courses, instructional
            online videos, and audio-visual online content (collectively,
            “Interactive Content”) through the PH Website or the websites of our
            third party service providers.
          </p>
          <p>
            (a) PH Products. PH reserves all intellectual property rights in and
            to any PH Products. The purchase of any PH Products does not include
            a license for you or your Authorized Users to reproduce, modify,
            adapt, translate, publicly perform, publicly display, transmit, or
            create any derivative works of the PH Products.
            <br /> (b) Electronic Copies. Subject to your compliance with this
            Agreement and your payment of all applicable fees, your purchase of
            Electronic Materials includes a personal, non-exclusive,
            non-transferable, non-sublicensable, revocable, right and license to
            print and distribute the Electronic Materials solely for
            non-commercial purposes, as part of teaching the PH curriculum;
            provided that the Electronic Materials and any reproductions thereof
            are only distributed to your Authorized Users as part of instruction
            provided by you. Unless a license has been purchased for an entire
            school or other similar organization, Electronic Materials may not
            be distributed to other teachers or instructors. Except as expressly
            set forth above, PH reserves all intellectual property rights in and
            to the Electronic Materials. The purchase of any Electronic
            Materials does not include a license to modify, adapt, translate, or
            create any derivative works of the Electronic Materials.
            <br /> (c) Interactive Content. Access to Interactive Content may
            require an account and password on the PH Website or the websites of
            our third party service providers, and may require you or your
            Authorized Users to agree to terms and conditions related to the
            access and use of any such third party’s technology. You agree that
            you will not, and you will not permit your Authorized Users to,
            share or otherwise transfer such account credentials or passwords
            with any third party. You agree that you and such Authorized Users
            will only use the Interactive Content in a non-commercial,
            educational manner in connection with teaching and/or learning the
            applicable Curriculum Materials.
          </p>
          <p>
            5.4 License Restrictions. Except as expressly permitted herein or by
            the Commercial Curriculum License (as defined below), the rights
            granted to you for any Curriculum Materials do not include, and
            specifically exclude, any rights to: (a) resell or make any
            commercial use of the Curriculum Materials; (b) make any derivative
            works, adaptations, translations or other modifications of the
            Curriculum Materials or any part thereof; or (c) use any form of
            data mining, robots, or similar data gathering and extraction tools
            in order to access the Curriculum Materials. You may not embed or
            frame, or utilize framing techniques to enclose, any Curriculum
            Materials in or on any other website, application (including mobile
            applications), or other systems, except as expressly permitted under
            the Creative Commons License. Any unauthorized use of Curriculum
            Materials automatically terminates the license granted to you
            hereunder.
          </p>
          <p>
            5.5 Non-Commercial Use. The Curriculum Materials are intended for
            personal, non-commercial use only. Without limiting the foregoing,
            the Curriculum Materials may not be used, distributed, or otherwise
            exploited “for commercial advantage or private monetary
            compensation” unless you first acquire a Commercial License (as
            defined below) or such use is otherwise agreed in writing by PH. The
            following are types of uses that PH expressly defines as falling
            outside the definition of “non-commercial”:
          </p>
          <p>
            (a) The sale or rental of (i) any part of the Curriculum Materials,
            (ii) any derivative works based at least in part on the Curriculum
            Materials, or (iii) any collective work that includes any part of
            the Curriculum Materials;
            <br /> (b) Providing products or services for remuneration that
            contain links to or reference (directly or indirectly) the
            Curriculum Materials, whether with or without a fee;
            <br /> (c) The offering of units of study (whether online or
            offline) that include Curriculum Materials, or any portion of the
            Curriculum Materials, in exchange for a fee (including, but not
            limited to, courses offered online or in connection with any
            wellness training).
            <br /> (d) Providing training, support, or educational services that
            use or reference the Curriculum Materials or any other PH resources
            or services in exchange for a fee;
            <br /> (e) The sale of advertisements, sponsorships, or promotions
            placed on the Curriculum Materials, or any part thereof, or the sale
            of advertisements, sponsorships, or promotions on any website or
            blog containing any part of the Curriculum Materials, including
            without limitation any “pop-up advertisements.”
          </p>
          <p>
            5.6 Commercial License. If you are interested in use of Curriculum
            Materials other than as permitted pursuant to Section 5.3 or use
            that is otherwise commercial in nature, you must first submit to PH
            a prior written request describing your intended purpose and use
            (“Request for Commercial Use”). Subject to PH’s written approval
            following of your Request for Commercial Use, and subject to your
            compliance with all terms and conditions herein and that PH may
            include with such approval (including payment of all applicable
            fees), PH will grant you a non-exclusive, non-transferable,
            non-sublicensable, limited license to use, display and distribute
            the specific Curriculum Materials identified in the Request for
            Commercial Use solely for the purposes disclosed in the Request for
            Commercial Use (“Approved Commercial Purpose”), and only for the
            duration of the activities necessary therefore (“Commercial
            Curriculum License”). You may make a reasonable number of printed
            copies of the approved Curriculum Materials solely to the extent
            necessary to implement the Approved Commercial Purpose, so long as
            all such copies comply with the attribution requirements of Section
            5.5.
          </p>
          <p>
            5.7 Attribution. The Creative Commons License and Commercial
            Curriculum License each requires attribution to PH in association
            with all use of the Curriculum Materials. Accordingly, if you
            distribute, publicly perform or display, transmit, publish, or
            otherwise make available any Curriculum Materials, you must also
            provide the following notice prominently along with the Curriculum
            Materials:{' '}
            <strong>
              “All Project Happiness Materials are owned by Project Happiness or
              its licensees, and are made available for non-commercial purposes
              at{' '}
              <a href="http://www.projecthappiness.org">
                <a href="http://www.projecthappiness.org">
                  http://www.projecthappiness.org
                </a>
              </a>
              ”
            </strong>
            .
          </p>
          <p>
            <strong>6. FEES; PAYMENTS</strong>
            <br /> 6.1 Fees. PH may now, or in the future, charge fees for
            access to and use of the Website, or certain features thereof, or
            for certain Curriculum Materials (“Fees”). You agree to pay all Fees
            and charges specified for such features or materials. All Fees are
            exclusive of applicable taxes (e.g. sales, use, or value-added tax),
            unless otherwise stated, and you are solely responsible for the
            payment of any such taxes that may be imposed on your use of the
            Website or Curriculum Materials.
          </p>
          <p>
            6.2 Payment Methods. PH will charge the Fee, if any, and other
            additional fees or donations you authorize, to the PayPal account or
            credit card you designate. You authorize the credit card or PayPal
            account you designate to pay any amounts described herein and
            authorize PH, or any other company that acts as a billing agent for
            PH, to continue to attempt to charge all sums described herein to
            your credit card or PayPal account until such Fees are paid in full.
            You represent and warrant that all information you provide to PH in
            connection with payment processing is accurate and up-to-date. You
            agree to provide PH updated information regarding your credit card
            and PayPal account upon PH’s request, and any time the information
            earlier provided is no longer valid. If payment is not received by
            PH from your credit card issuer or PayPal, you agree to pay all
            amounts due upon demand by PH.
          </p>
          <p>
            6.3 Credit Card Authorization. If PH permits you to use a credit
            card to pay for any Fees, you will be asked to provide PH with a
            credit card number from a card issuer that PH accepts. PH may seek
            pre-authorization of your credit card account prior to a purchase to
            verify the credit card is valid and/or has the necessary funds or
            credit available to cover your purchase. These pre-authorizations
            may reduce your available balance by the authorization amount until
            it is released or reconciled with the actual charge. Please contact
            your credit card issuer if you have additional questions regarding
            when an authorization amount will be removed from your statement.
          </p>
          <p>
            6.4 Changes in Price; No Refunds. PH may at any time, upon notice
            required by applicable law, (a) change the price charged for any
            Website Content, features or materials, including without limitation
            for any Curriculum Materials, (b) institute new charges or fees
            therefore, or (c) charge a fee where a fee was not previously
            charged. All Fees and amounts paid hereunder are final and
            nonrefundable.
          </p>
          <p>
            <strong>7. USE AND PROTECTION OF PASSWORD AND ID.</strong>
            <br /> PH may assign a password and account ID to you so you can
            access and use certain areas of the Website. Each user who uses such
            assigned password and ID shall be deemed to be authorized by you to
            access and use the Website on your behalf, and PH shall have no
            obligation to investigate the authorization or source of any such
            access or use. YOU ACKNOWLEDGE AND AGREE THAT, AS BETWEEN YOU AND
            PH, YOU WILL BE SOLELY RESPONSIBLE FOR ALL ACCESS TO AND USE OF THE
            WEBSITE BY ANYONE USING THE PASSWORD AND ID ASSIGNED TO YOU WHETHER
            OR NOT SUCH ACCESS TO AND USE OF THE WEBSITE IS ACTUALLY AUTHORIZED
            BY YOU, INCLUDING ALL COMMUNICATIONS AND TRANSMISSIONS AND ALL
            OBLIGATIONS (INCLUDING FINANCIAL OBLIGATIONS FOR PURCHASES THROUGH
            THE WEBSITE) THAT MAY RESULT FROM SUCH ACCESS OR USE. You are solely
            responsible for protecting the security and confidentiality of the
            password and ID assigned to you. You shall immediately notify PH of
            any unauthorized use of the assigned password or ID, or any other
            breach or threatened breach of the Website’s security of which you
            are aware. You will be responsible for any activity conducted under
            your assigned password or ID.
          </p>
          <p>
            <strong>8. SYSTEM REQUIREMENTS.</strong>
            <br /> Use of the certain areas of the Website requires Internet
            access, audio manager software or other software allowing the
            downloading and storing of audio and audio-visual files in MP3 or
            other digital format (the “Software”), and, for certain downloadable
            content, a compatible player device (the “Device”). PH may, at any
            time and from time to time, in its sole discretion, modify, revise,
            or otherwise change the system requirements for the Website and the
            format of any downloadable content, in whole or in part, without
            notice or liability to you.
          </p>
          <p>
            Internet access, use of the Software, or use of a Device may result
            in fees in addition to any fees incurred on the Website. Software
            and Devices may require you to obtain updates or upgrades from time
            to time. Your ability to use the Website may be affected by the
            performance of the Software, the Device, or your Internet
            connection. You acknowledge and agree that it is your sole
            responsibility to comply with the system requirements of your
            Software and Device, as in effect from time to time, and to
            maintain, update, and upgrade your Software and Devices, including
            the payment of all Internet access, Software, and Device fees
            without recourse to PH.
          </p>
          <p>
            <strong>9. SUBMISSIONS</strong>
            <br /> You may submit and post reviews, comments, photographs, blog
            entries, and other content; send communications; and submit
            suggestions, ideas, comments, questions, or other information, so
            long as none of these materials are illegal, obscene, threatening,
            defamatory, invasive of privacy, infringing on intellectual property
            rights, or otherwise injurious to third parties or objectionable and
            do not consist of or contain software viruses, political
            campaigning, commercial solicitation, chain letters, mass mailings,
            or any form of “spam.” You may not use a false e-mail address,
            impersonate anyone, or otherwise mislead as to the origin of any
            content. We reserve the right (but not the obligation) to remove or
            edit any such content.
          </p>
          <p>
            Please do not send us any material that you do not intend to be
            subject to the User-Generated Content License described in this
            paragraph. All content described in the immediately preceding
            paragraph and any and all other information, content or materials
            that you post or send to us hereinafter collectively is referred to
            as “User-Generated Content.” If you post or send any User-Generated
            Content to us, intentionally or unintentionally, we (and such others
            as we may designate from time to time) shall have the unrestricted
            rights (but no obligation) to the use thereof for any and all
            purposes whatsoever, commercial or otherwise, without any further
            permission from, or any notice or payment to, you or anyone else. We
            and our designees also shall have the right (but no obligation) to
            use the name that you submit, as well as any other name by which you
            are or may be known, in connection with User-Generated Content.
            Without limiting the generality of the foregoing, you hereby
            unconditionally grant to us a perpetual, non-exclusive, irrevocable,
            fully-paid, royalty-free, sub-licensable and transferable universal
            license to use, re-use, reproduce, transmit, print, publish,
            display, exhibit, distribute, re-distribute, copy, host, store,
            cache, archive, index, categorize, comment on, broadcast, stream,
            download, edit, alter, modify, adapt, translate, create derivative
            works based upon and publicly perform User-Generated Content, in
            whole or in part, by all means and in all media now known or
            hereafter devised for any and all purposes without further notice to
            you and with or without attribution (the “User-Generated Content
            License”). You agree to the User-Generated Content License whether
            or not your User-Generated Content is used by us.
          </p>
          <p>
            You represent, warrant and agree that: you own or otherwise control
            all of the rights to all User-Generated Content that you post or
            send to us; that all such User-Generated Content is accurate; use of
            such User-Generated Content does not violate these Terms of Use, our
            Privacy Policy or the rights of any third party and will not cause
            injury to anyone; and you will indemnify us and our affiliates and
            designees from and against all claims arising out of, resulting from
            or relating to any such User-Generated Content. If your
            User-Generated Content contains a factual error, you must notify us
            of the error immediately.{' '}
            <strong>
              We have the right (but no obligation) to monitor, edit or remove
              any activity or content involving you. We have no responsibility,
              and assume no liability, for any User-Generated Content posted or
              sent by you or by anyone else.
            </strong>
          </p>
          <p>
            You agree that User-Generated Content will not be subject to any
            expectation of trust or confidence between us, and that no
            confidential or fiduciary relationship is intended or created
            between you and us. To the extent that any so-called “moral rights,”
            “neighboring rights” or similar or analogous rights apply to any
            User-Generated Content and which are not exclusively owned by us,
            you agree not to enforce or assign, or permit any third party to
            enforce or assign, any such rights.
          </p>
          <p>
            Each time that you access the Website, or post or submit
            User-Generated Content, you agree that the User-Generated Content
            License is ratified and confirmed with respect to such
            User-Generated Content and all User-Generated Content previously
            posted or submitted by you.
          </p>
          <p>
            <strong>10. CONTENT LINKED TO THE WEBSITE</strong>
            <br /> You should be aware that when you visit the Website, you
            could be directed to other sites beyond our control including links
            to or from affiliates and content partners that may use our Marks as
            part of an affiliate relationship. When you click on a link that
            directs you away from the Website, the site to which you are
            directed may not be controlled by us and different terms of use and
            privacy policies may apply which you should carefully read and
            evaluate. You acknowledge that we are not responsible for examining
            or evaluating, and that we do not warrant the offerings of, any such
            third party or the content of their sites. We do not assume any
            responsibility or liability for the actions, products, or content of
            any third party or any third party site. We reserve the right to
            disable links from or to third-party sites, although we are under no
            obligation to do so.
          </p>
          <p>
            <strong>11. DISCLAIMER OF WARRANTIES</strong>
            <br />{' '}
            <strong>
              THE CONTENT ON THE WEBSITE IS PROVIDED “AS IS” AND WITHOUT
              WARRANTY OF ANY KIND, EXPRESSED OR IMPLIED. TO THE FULLEST EXTENT
              PERMITTED BY APPLICABLE LAW, WE DISCLAIM ANY AND ALL WARRANTIES,
              EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE
              AND NON-INFRINGEMENT. WE DO NOT WARRANT THAT THE FUNCTIONS
              CONTAINED IN ANY CONTENT (INCLUDING, WITHOUT LIMITATION,
              USER-GENERATED CONTENT) WILL BE UNINTERRUPTED OR ERROR-FREE, THAT
              DEFECTS WILL BE CORRECTED, OR THAT THE WEBSITE OR THE SERVERS THAT
              MAKE SUCH CONTENT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
              COMPONENTS AND YOU ASSUME THE ENTIRE COST OF ALL NECESSARY
              SERVICING, REPAIR OR CORRECTION OF ANY OF YOUR EQUIPMENT OR
              SOFTWARE. WE MAKE NO REPRESENTATIONS OR WARRANTIES REGARDING USE,
              OR THE RESULTS OF USE, OF ANY CONTENT, PRODUCT OR SERVICE
              CONTAINED ON OR OFFERED, MADE AVAILABLE THROUGH, OR OTHERWISE
              RELATED IN ANY WAY TO THE WEBSITE INCLUDING, WITHOUT LIMITATION,
              ANY THIRD PARTY SITE OR SERVICE LINKED TO FROM THE WEBSITE (AND
              SPECIFICALLY NO REPRESENTATION OR WARRANTY OF CORRECTNESS,
              ACCURACY, COMPLETENESS, RELIABILITY OR SAFETY).
            </strong>
          </p>
          <p>
            WE EXPLICITLY DISCLAIM ANY RESPONSIBILITY FOR THE ACCURACY,
            COMPLETENESS OR AVAILABILITY OF INFORMATION, CONTENT AND MATERIALS
            FOUND ON SITES THAT LINK TO OR FROM THE WEBSITE. WE CANNOT ENSURE
            THAT YOU WILL BE SATISFIED WITH ANY PRODUCT OR SERVICE THAT YOU
            PURCHASE FROM A THIRD PARTY WEBSITE THAT LINKS TO OR FROM THE
            WEBSITE OR THIRD PARTY INFORMATION, CONTENT OR MATERIALS CONTAINED
            ON OUR WEBSITE. WE DO NOT ENDORSE ANY OF THE MERCHANDISE, NOR HAVE
            WE TAKEN ANY STEPS TO CONFIRM THE ACCURACY, COMPLETENESS OR
            RELIABILITY OF, ANY OF THE INFORMATION, CONTENT OR MATERIALS
            CONTAINED ON ANY THIRD PARTY WEBSITE. WE DO NOT MAKE ANY
            REPRESENTATIONS OR WARRANTIES AS TO THE SECURITY OF ANY INFORMATION,
            CONTENT OR MATERIALS (INCLUDING, WITHOUT LIMITATION, CREDIT CARD AND
            OTHER PERSONAL INFORMATION) YOU MIGHT BE REQUESTED TO GIVE TO ANY
            THIRD PARTY. YOU HEREBY IRREVOCABLY AND UNCONDITIONALLY WAIVE ANY
            AND ALL CLAIMS AGAINST US WITH RESPECT TO INFORMATION, CONTENT AND
            MATERIALS CONTAINED ON THE WEBSITE (INCLUDING, WITHOUT LIMITATION,
            USER-GENERATED CONTENT), ON THIRD PARTY SITES, AND ANY INFORMATION,
            CONTENT AND MATERIALS YOU PROVIDE TO OR THROUGH ANY SUCH THIRD PARTY
            SITES (INCLUDING, WITHOUT LIMITATION, CREDIT CARD AND OTHER PERSONAL
            INFORMATION). WE STRONGLY ENCOURAGE YOU TO MAKE WHATEVER
            INVESTIGATION YOU FEEL NECESSARY OR APPROPRIATE BEFORE PROCEEDING
            WITH ANY ONLINE OR OFFLINE TRANSACTION WITH ANY THIRD PARTY.
          </p>
          <p>
            THE WEBSITE AND PRODUCTS/SERVICES CONTAINED THEREON ARE NOT
            SUBSTITUTES FOR THE ADVICE AND TREATMENT OF A LICENSED HEALTH CARE
            PROFESSIONAL. NOT ALL PRODUCTS AND SERVICES ARE SUITED FOR EVERYONE.
            THE CREATORS OF ANY PRODUCTS/SERVICES DO NOT ASSUME, AND SHALL NOT
            HAVE, ANY LIABILITY TO USERS FOR INJURY OR LOSS IN CONNECTION
            THEREWITH. WE MAKE NO REPRESENTATIONS OR WARRANTIES AND EXPRESSLY
            DISCLAIM ANY AND ALL LIABILITY CONCERNING ANY TREATMENT OR ANY
            ACTION FOLLOWING THE INFORMATION OFFERED OR PROVIDED WITHIN OR
            THROUGH THE WEBSITE. IF YOU HAVE SPECIFIC CONCERNS OR A SITUATION IN
            WHICH YOU REQUIRE PROFESSIONAL OR MEDICAL ADVICE, YOU SHOULD CONSULT
            WITH AN APPROPRIATELY TRAINED AND QUALIFIED SPECIALIST, SUCH AS A
            LICENSED PSYCHOLOGIST, PHYSICIAN OR OTHER HEALTH PROFESSIONAL. NEVER
            DISREGARD THE MEDICAL ADVICE OF A PSYCHOLOGIST, PHYSICIAN OR OTHER
            HEALTH PROFESSIONAL, OR DELAY IN SEEKING SUCH ADVICE, BECAUSE OF THE
            INFORMATION OFFERED OR PROVIDED WITHIN OR THROUGH THE WEBSITE.
          </p>
          <p>
            YOU ACKNOWLEDGE THAT YOU HAVE CAREFULLY READ THIS “WAIVER AND
            RELEASE” AND FULLY UNDERSTAND THAT IT IS A RELEASE OF LIABILITY. YOU
            EXPRESSLY AGREE TO RELEASE AND DISCHARGE ALL INDEMNIFIED PARTIES (AS
            DEFINED BELOW) FROM ANY AND ALL CLAIMS OR CAUSES OF ACTION AND YOU
            AGREE TO VOLUNTARILY GIVE UP AND IRREVOCABLY WAIVE AND RELEASE ANY
            RIGHT THAT YOU MAY OTHERWISE HAVE TO BRING A LEGAL ACTION AGAINST
            ANY INDEMNIFIED PARTY FOR PERSONAL INJURY OR PROPERTY DAMAGE.
          </p>
          <p>
            <strong>
              CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES
              OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS
              APPLY TO YOU, SOME OR ALL OF THE FOREGOING DISCLAIMERS, EXCLUSIONS
              OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL
              RIGHTS.
            </strong>
          </p>
          <p>
            <strong>12. INDEMNIFICATION</strong>
            <br /> You hereby agree to indemnify, defend, and hold us, and our
            licensors, licensees, successors, distributors, agents,
            representatives and other authorized users, and each of their
            respective officers, directors, owners, managers, members,
            employees, agents, representatives and assigns (collectively, the
            “Indemnified Parties”), harmless from and against any and all loss,
            cost, damage, liability and expense (including, without limitation,
            settlement costs and legal or other fees and expenses) suffered or
            incurred by any of the Indemnified Parties arising out of, in
            connection with or related to any breach or alleged breach by you of
            these Terms of Use. You shall use your best efforts to cooperate
            with us in the defense of any claim. We reserve the right, at our
            own expense, to employ separate counsel and assume the exclusive
            defense and control of the settlement and disposition of any claim
            that is subject to indemnification by you.
          </p>
          <p>
            <strong>13. LIMITATION OF LIABILITY</strong>
            <br />{' '}
            <strong>
              UNDER NO CIRCUMSTANCES (INCLUDING NEGLIGENCE) SHALL PH BE LIABLE
              TO YOU OR ANYONE ELSE FOR ANY INDIRECT, INCIDENTAL, SPECIAL OR
              CONSEQUENTIAL DAMAGES (INCLUDING LOST PROFITS, REVENUE, OR DATA)
              OF ANY KIND OR NATURE WHATSOEVER THAT ARISE OUT OF OR RESULT FROM:
              (A) THE USE OF, OR ANY INABILITY TO USE, THE WEBSITE OR ANY
              CONTENT OR FUNCTIONS THEREOF; OR (B) ANY ACT OR OMISSION, ONLINE
              OR OFFLINE, OF ANY USER OF THE WEBSITE OR ANYONE ELSE, EVEN IF WE
              HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT
              SHALL PH’S TOTAL LIABILITY TO YOU FOR ALL LOSS, COST, DAMAGE,
              LIABILITY OR EXPENSE (INCLUDING ATTORNEYS FEES AND COSTS) THAT YOU
              MAY SUFFER OR INCUR, UNDER ANY THEORY OF LIABILITY, IN CONTRACT,
              TORT (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE) OR OTHERWISE,
              EXCEED THE GREATER OF THE AMOUNT PAID BY YOU HEREUNDER, IF ANY,
              FOR THE RIGHT TO ACCESS OR PARTICIPATE IN ANY ACTIVITY RELATED TO
              THE WEBSITE OR $100.00.
            </strong>
          </p>
          <p>
            UNDER NO CIRCUMSTANCES SHALL WE OR ANY OF THE INDEMNIFIED PARTIES BE
            LIABLE FOR ANY DELAY OR FAILURE IN PERFORMANCE RESULTING, DIRECTLY
            OR INDIRECTLY, FROM ANY EVENT OF FORCE MAJEURE OR OTHER CAUSE BEYOND
            OUR OR THEIR CONTROL INCLUDING, WITHOUT LIMITATION, ACTS OF GOD,
            WAR, EQUIPMENT AND TECHNICAL FAILURES, ELECTRICAL POWER FAILURES OR
            FLUCTUATIONS, STRIKES, LABOR DISPUTES, RIOTS, CIVIL DISTURBANCES,
            SHORTAGES OF LABOR OR MATERIALS, NATURAL DISASTERS, GOVERNMENTAL
            ACTIONS, ORDERS OF DOMESTIC OR FOREIGN COURTS OR TRIBUNALS, OR
            NON-PERFORMANCE OF THIRD PARTIES. NEITHER WE NOR ANY OTHER
            INDEMNIFIED PARTY IS RESPONSIBLE OR LIABLE FOR: (A) ANY
            INCOMPATIBILITY BETWEEN THE WEBSITE AND ANY SITE, SERVICE, SOFTWARE
            OR HARDWARE; OR (B) ANY DELAY OR FAILURE YOU MAY EXPERIENCE WITH ANY
            TRANSMISSION OR TRANSACTION RELATED TO THE WEBSITE.
          </p>
          <p>
            THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS HEREIN AND ELSEWHERE IN
            THESE TERMS OF USE APPLY TO THE MAXIMUM EXTENT PERMITTED BY
            APPLICABLE LAW.
          </p>
          <p>
            <strong>
              APPLICABLE LAW MAY NOT ALLOW CERTAIN OF THE EXCLUSIONS,
              LIMITATIONS, OR DISCLAIMERS OF LIABILITY SET FORTH IN THESE TERMS
              OF USE, SO SUCH EXCLUSIONS, LIMITATIONS OR DISCLAIMERS MAY NOT
              APPLY TO YOU.
            </strong>
          </p>
          <p>
            <strong>14. COPYRIGHT COMPLAINTS</strong>
            <br /> We respect the intellectual property rights of others. If you
            believe that your work has been copied on the Website in a way that
            constitutes copyright infringement, please follow our Notice and
            Procedure for Making Claims of Copyright Infringement.
          </p>
          <p>
            <strong>15. AMENDMENT</strong>
            <br /> We reserve the right, at our discretion, to change these
            Terms on a going-forward basis at any time. Please check these Terms
            periodically for changes. In the event that a change to these Terms
            materially modifies your rights or obligations, we will make
            reasonable efforts to notify you of such change. We may provide
            notice through a pop-up or banner within the Website, by sending an
            email to any address you may have used to register for an account,
            or through other mechanisms. Generally, modifications to the Terms
            are effective upon publication, provided that if the changed Terms
            materially modify your rights or obligations, the changes are
            effective upon the earlier of (a) your using the Website with actual
            knowledge of the change, or (b) thirty days following publication of
            the modified Terms, and further provided that disputes arising under
            these Terms will be resolved in accordance with the Terms in effect
            that the time the dispute arose. If you do not accept the changed
            Terms, you may not continue access to and use of the Website,
            including any Curriculum Materials.
          </p>
          <p>
            <strong>16. TERMINATION</strong>
            <br /> These Terms of Use are effective until terminated by either
            you or us. You may terminate these Terms of Use prospectively at any
            time by discontinuing your access to and use of the Website and
            destroying all materials obtained from the Website and all related
            documentation and all copies and installations thereof, whether made
            under these Terms of Use or otherwise. If you terminate these Terms
            of Use, you shall notify us by sending notice of such termination by
            certified United States mail, postage pre-paid to:
          </p>
          <p>
            <strong>
              Project Happiness 20370 Town Center Lane Suite 254, Cupertino CA
              95014
            </strong>
          </p>
          <p>
            We may terminate these Terms of Use (including your access to and
            use of the Website) without cause and without notice to you, in our
            sole discretion. Upon termination, you must cease any access to or
            use of the Website and destroy all materials obtained from the
            Website and all related documentation and all copies and
            installations thereof, whether made under these Terms of Use or
            otherwise. We have adopted and implemented a policy that provides
            for the termination, in appropriate circumstances, of users who are
            repeat infringers of copyright. The provisions of these Terms of
            Use, which by their nature should survive the termination of these
            Terms of Use, shall survive such termination.
          </p>
          <p>
            <strong>17. DISPUTE RESOLUTION AND ARBITRATION</strong>
            <br /> 17.1 Generally. In the interest of resolving disputes between
            you and PH in the most expedient and cost effective manner, you and
            PH agree that every dispute arising in connection with these Terms
            will be resolved by binding arbitration. Arbitration is more
            informal than a lawsuit in court. Arbitration uses a neutral
            arbitrator instead of a judge or jury, may allow for more limited
            discovery than in court, and can be subject to very limited review
            by courts. Arbitrators can award the same damages and relief that a
            court can award. Our agreement to arbitrate disputes includes all
            claims arising out of or relating to any aspect of these Terms,
            whether based in contract, tort, statute, fraud, misrepresentation,
            or any other legal theory, and regardless of whether a claim arises
            during or after the termination of these Terms. YOU UNDERSTAND AND
            AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND PH ARE EACH
            WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS
            ACTION.
          </p>
          <p>
            17.2 Exceptions. Despite the provisions of Section 17.1, we both
            agree that nothing in these Terms will be deemed to waive, preclude,
            or otherwise limit the right of either of you or PH to: (a) bring an
            individual action in small claims court; (b) pursue an enforcement
            action through the applicable federal, state, or local agency if
            that action is available; (c) seek injunctive relief in a court of
            law; or (d) to file suit in a court of law to address an
            intellectual property infringement claim.
          </p>
          <p>
            17.3 Arbitrator. Any arbitration between you and PH will be governed
            by the Commercial Dispute Resolution Procedures and the
            Supplementary Procedures for Consumer Related Disputes
            (collectively, “AAA Rules”) of the American Arbitration Association
            (“AAA”), as modified by these Terms, and will be administered by the
            AAA. The AAA Rules and filing forms are available online at{' '}
            <a href="http://www.adr.org">www.adr.org</a>, by calling the AAA at
            1-800-778-7879, or by contacting PH.
          </p>
          <p>
            17.4 Notice; Process. A party who intends to seek arbitration must
            first send a written notice of the dispute to the other, by
            certified mail or Federal Express (signature required), or if we do
            not have a physical address on file for you, by electronic mail
            (“Notice”). PH’s address for Notice is:
          </p>
          <p>
            Project Happiness
            <br /> 20370 Town Center Lane
            <br /> Suite 254
            <br /> Cupertino, CA 95014
          </p>
          <p>
            The Notice must:
            <br /> (a) describe the nature and basis of the claim or dispute;
            and (b) set forth the specific relief sought (“Demand”). We agree to
            use good faith efforts to resolve the claim directly, but if we do
            not reach an agreement to do so within thirty (30) days after the
            Notice is received, you or PH may commence an arbitration
            proceeding. During the arbitration, the amount of any settlement
            offer made by you or PH must not be disclosed to the arbitrator
            until after the arbitrator makes a final decision and award, if any.
            If our dispute is finally resolved through arbitration in your
            favor, PH will pay you the highest of the following: (i) the amount
            awarded by the arbitrator, if any; (ii) the last written settlement
            amount offered by PH in settlement of the dispute prior to the
            arbitrator’s award; or (iii) $1,000.
          </p>
          <p>
            17.5 Fees. If you commence arbitration in accordance with these
            Terms, PH will reimburse you for your payment of the filing fee,
            unless your claim is for more than $10,000, in which case the
            payment of any fees will be decided by the AAA Rules. Any
            arbitration hearing will take place at a location to be agreed upon
            in Santa Clara County, California, but if the claim is for $10,000
            or less, you may choose whether the arbitration will be conducted:
            (a) solely on the basis of documents submitted to the arbitrator;
            (b) through a non-appearance based telephone hearing; or (c) by an
            in-person hearing as established by the AAA Rules in the county (or
            parish) of your billing address. If the arbitrator finds that either
            the substance of your claim or the relief sought in the Demand is
            frivolous or brought for an improper purpose (as measured by the
            standards set forth in Federal Rule of Civil Procedure 11(b)), then
            the payment of all fees will be governed by the AAA Rules. In that
            case, you agree to reimburse PH for all monies previously disbursed
            by it that are otherwise your obligation to pay under the AAA Rules.
            Regardless of the manner in which the arbitration is conducted, the
            arbitrator must issue a reasoned written decision sufficient to
            explain the essential findings and conclusions on which the decision
            and award, if any, are based. The arbitrator may make rulings and
            resolve disputes as to the payment and reimbursement of fees or
            expenses at any time during the proceeding and upon request from
            either party made within fourteen (14) days of the arbitrator’s
            ruling on the merits.
          </p>
          <p>
            17.6 No Class Actions. YOU AND PH AGREE THAT EACH MAY BRING CLAIMS
            AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS
            A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
            PROCEEDING. Further, unless both you and PH agree otherwise, the
            arbitrator may not consolidate more than one person’s claims, and
            may not otherwise preside over any form of a representative or class
            proceeding.
          </p>
          <p>
            17.7 Modifications. If PH makes any future change to this
            arbitration provision (other than a change to PH’s address for
            Notice), you may reject the change by sending us written notice
            within thirty (30) days of the change to PH’s address for Notice, in
            which case your account with PH will be immediately terminated and
            this arbitration provision, as in effect immediately prior to the
            amendments you reject, will survive.
          </p>
          <p>
            17.8 Enforceability. If Section 17.6 is found to be unenforceable or
            if the entirety of this Section 17 is found to be unenforceable,
            then the entirety of this Section 17 will be null and void and, in
            that case, the parties agree that the exclusive jurisdiction and
            venue described in Section 18 will govern any action arising out of
            or related to these Terms.
          </p>
          <p>
            <strong>18. GOVERNING LAW</strong>
            <br /> These Terms are governed by the laws of the State of
            California without regard to conflict of law principles. If a
            lawsuit or court proceeding is permitted under these Terms, then,
            unless another location is expressly specified in these Terms, you
            and PH agree to submit to the personal and exclusive jurisdiction of
            the state courts and federal courts located within Santa Clara
            County, California for the purpose of litigating any dispute. We
            operate our services from our offices in California, and we make no
            representation that the Content included as a part of our services
            or on the Website is appropriate or available for use in other
            locations.
          </p>
          <p>
            <strong>19. ELECTRONIC COMMUNICATIONS</strong>
            <br /> When you visit the Website or send e-mails to us, you are
            communicating with us electronically. You consent to receive
            communications from us electronically. We will communicate with you
            by e-mail or by posting notices on the Website. You agree that all
            agreements, notices, disclosures and other communications that we
            provide to you electronically satisfy any legal requirement that
            such communications be in writing.
          </p>
          <p>
            <strong>20. MISCELLANEOUS LEGAL PROVISIONS</strong>
            <br /> We may discontinue the Website, or any part thereof, at any
            time and for any reason, without notice. We may change the contents,
            operation, or features of the Website at any time for any reason,
            without notice. You agree that no joint venture, partnership,
            employment, or agency relationship exists between you and us as a
            result of these Terms of Use or your use of the Website. Nothing
            contained in these Terms of Use is in derogation of our right to
            comply with governmental, court, and law enforcement requests or
            requirements relating to your use of the Website or information
            provided to or gathered by us with respect to such use. A printed
            version of these Terms of Use and of any notice given in electronic
            form shall be admissible in judicial or administrative proceedings
            based upon or relating to these Terms of Use to the same extent and
            subject to the same conditions as other business documents and
            records originally generated and maintained in printed form. Our
            failure to enforce any provision of these Terms of Use or respond to
            a breach by you or others shall not constitute a waiver of our right
            to enforce any other provision of these Terms of Use as to that
            breach or any other.
          </p>
          <p>
            If any provision of these Terms of Use is invalid or unenforceable
            under applicable law, the remaining provisions will continue in full
            force and effect, and the invalid or unenforceable provision will be
            deemed superseded by a valid, enforceable provision that most
            closely matches the intent of the original provision. These Terms of
            Use constitute the entire agreement between you and us regarding the
            Website and supersedes any prior or contemporaneous agreement
            regarding that subject matter.
          </p>
          <p>Updated May 2015</p>
          <p>
            <strong>
              Notice and Procedure for Making Claims of Copyright Infringement
            </strong>
          </p>
          <p>
            If you believe that your work has been copied in a way that
            constitutes copyright infringement, please provide our copyright
            agent the written information specified below. Please note that this
            procedure is exclusively for notifying us that your copyrighted
            material has been infringed.
          </p>
          <ul>
            <li>
              An electronic or physical signature of the person authorized to
              act on behalf of the owner of the copyright interest;
            </li>
            <li>
              A description of the copyrighted work that you claim has been
              infringed;
            </li>
            <li>
              A description of where the material that you claim is infringing
              is located on the Website;
            </li>
            <li>Your address, telephone number, and e-mail address;</li>
            <li>
              A statement by you that you have a good-faith belief that the
              disputed use is not authorized by the copyright owner, its agent,
              or the law;
            </li>
            <li>
              A statement by you, under penalty of perjury, that the foregoing
              information in your notice is accurate and that you are the
              copyright owner or authorized to act on the copyright owner’s
              behalf.
            </li>
          </ul>
          <p>
            The PH’s Copyright Agent for notice of claims of copyright
            infringement on the Website can be reached as follows:
          </p>
          <p>
            <strong>
              Project Happiness 20370 Town Center Lane Suite 254, Cupertino, CA
              95014
            </strong>
          </p>
          <p>Updated 2017</p>
        </div>
      </LayoutUI>
    </div>
  </Layout>
);

export default Terms;
